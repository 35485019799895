import React from "react";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Link = styled(OutboundLink)`
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.25em;
    fill: currentColor;
  }
`;

export default function ExternalLink({ children, href, ...props }) {
  return (
    <Link href={href} {...props} target="_blank">
      {children}
      <svg viewBox="0 0 24 24" width="1em" height="1em">
        <path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
      </svg>
    </Link>
  );
}
