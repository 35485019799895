import React from "react";
import Helmet from "react-helmet";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import useSiteMetadata from "./SiteMetadata";
import logo from "../img/logo.svg";

const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    text: "#000000",
    primary: "#1890FF",
    primaryLight: "#e5f3ff",
    primaryInverse: "#FFFFFF"
  },
  borderRadius: "4px",
  "heading-weight": 700,
  "heading-lineheight": 1.15,
  "size-1": "3.5rem",
  "size-2": "2.625rem",
  "size-3": "1.5rem",
  "size-4": "1.125rem",
  "size-5": "1rem",
  "size-lead": "1.125rem",
  "size-base": "1rem",
  "size-small": "0.875rem"
};

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  h1, h2, h3, h4, h5 {
    font-weight: ${() => theme["heading-weight"]};
    line-height: ${() => theme["heading-lineheight"]};
  }

  h1 {
    font-size: ${() => theme["size-1"]};
  }

  h2 {
    font-size: ${() => theme["size-2"]};
  }

  h3 {
    font-size: ${() => theme["size-3"]};
  }

  h4 {
    font-size: ${() => theme["size-4"]};
  }

  h5 {
    font-size: ${() => theme["size-5"]};
  }

  ::selection {
    background: #ffeeea;
    color: #fd5730;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    color: ${theme.colors.text};
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }
`;

const TemplateWrapper = ({ children }) => {
  const { title, description, siteUrl } = useSiteMetadata();
  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:image" content={`${siteUrl}/img/og-image.png`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <script type="application/ld+json">
          {`
          { "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Appmantle",
          "slogan": "Een nieuwe manier van apps maken.",
          "description": "Een nieuwe manier van apps maken. Een complete moderne app, die je zelf snel & makkelijk in elkaar zet, zonder programmeerkennis.",
          "legalName": "Appmantle",
          "url": "${siteUrl}",
          "logo": "${siteUrl}${logo}",
          "areaServed": {
            "@type": "Country",
            "name": "NL"
          },
          "foundingDate": "2016",
          "founders": [
            {
            "@type": "Person",
            "name": "Arjan de Wit",
            "sameAs": [
              "https://www.linkedin.com/in/novatief/"
            ]
            },
            {
            "@type": "Person",
            "name": "Bert-Jan Verwer",
            "sameAs": [
              "https://www.linkedin.com/in/drukwekkend/"
            ]
            },
            {
            "@type": "Person",
            "name": "Leon Koole",
            "sameAs": [
              "https://www.linkedin.com/in/leon/"
            ]
            }
          ],
          "address": {
            "@type" : "PostalAddress",
            "streetAddress" : "Herestraat 106",
            "addressLocality" : "Groningen",
            "postalCode" : "9711 LM",
            "addressCountry": "NL"
          },
          "contactPoint": {
          "@type": "ContactPoint",
          "contactType": "customer support",
          "email": "hallo@appmantle.com",
          "url": "https://appmantle.com"
          },
          "sameAs": [ 
          "https://www.linkedin.com/company/17950003/",
          "https://www.instagram.com/appmantle",
          "https://twitter.com/appmantle",
          "https://github.com/appmantle"
          ]}`}
        </script>
      </Helmet>
      <ThemeProvider theme={theme}>
        <>
          <Navbar />
          <div>{children}</div>
          <Footer />
        </>
      </ThemeProvider>
    </div>
  );
};

export default TemplateWrapper;
