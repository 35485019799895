import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import logo from "../img/logo.svg";
import founded from "../img/founded.svg";
import Container from "./Container";
import H1 from "./H1";
import H2 from "./H2";
import H5 from "./H5";
import Button from "./Button";
import Paragraph from "./Paragraph";
import ExternalLink from "./ExternalLink";

const Price = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
`;

const PriceInfo = styled.div`
  opacity: 0.5;
  font-size: 0.8em;
  line-height: 1.5;
  margin-left: 1rem;
`;

const Copyright = styled.div`
  border-top: 1px solid #cbd1e4;
  margin-top: 2rem;
  padding-top: 2rem;
  color: #828796;
`;

const FoundedLink = styled.a`
  &:hover {
    opacity: 0.75;
    transition: 0.2s;
  }
`;

const Background = styled.div`
  background-color: #fafbff;
  padding: 3rem 0;
  position: relative;
`;

const FooterLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme["size-small"]};
  margin: 0.5em 0;
`;

const ExternalFooterLink = styled(ExternalLink)`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme["size-small"]};
  margin: 0.5em 0;
`;

const OverlayTop = styled.div`
  z-index: 2;
  height: 16px;
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(
    -180deg,
    rgba(159, 184, 198, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  pointer-events: none;
`;

const Footer = class extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Flex alignItems="center" flexWrap="wrap" py={[5, 6, 6]}>
            <Box width={[1, 1 / 2, 1 / 2]} pr={[0, 4, 4]}>
              <H2>Probeer Appmantle helemaal gratis</H2>
              <Paragraph>
                Bij Appmantle betaal je pas wanneer wij jouw app in de app
                stores zetten. Bouw je app zonder restricties en laat hem zien
                aan collega’s, klanten en vrienden. Publiceer de app wanneer jij
                er klaar voor bent.
              </Paragraph>
              <Button
                primary
                large
                withArrow
                as={OutboundLink}
                href="https://dashboard.appmantle.com/signup"
              >
                Begin nu gratis
              </Button>
            </Box>
            <Box
              width={[1, 1 / 2, 1 / 2]}
              pl={[0, 4, 5]}
              pr={[0, 0, 4]}
              pt={[5, 0, 0]}
              style={{ textAlign: "center" }}
            >
              <Price>
                <H1>€65</H1>
                <PriceInfo>
                  excl. btw
                  <br />
                  per maand
                </PriceInfo>
              </Price>
              <Paragraph>Eén prijs, alle functionaliteit.</Paragraph>
              <Button as={Link} to="/tarieven/">
                Meer informatie
              </Button>
            </Box>
          </Flex>
        </Container>
        <Background>
          <Container>
            <Flex flexWrap="wrap-reverse">
              <Box width={[1, 1 / 2, 2 / 6]} mt={[4, 4, 0]}>
                <img src={logo} alt="Appmantle" style={{ height: "2.5rem" }} />
                <Paragraph>
                  Herestraat 106
                  <br />
                  9711 LM Groningen
                  <br />
                  hallo@appmantle.com
                </Paragraph>
              </Box>
              <Box width={[0, 0, 1 / 6]} />
              <Box width={[1, 1 / 2, 1 / 6]} mt={[3, 4, 0]}>
                <H5>Platform</H5>
                <FooterLink to="/product/">Product</FooterLink>
                <FooterLink to="/tarieven/">Tarieven</FooterLink>
                <ExternalFooterLink href="https://dashboard.appmantle.com/">
                  Inloggen
                </ExternalFooterLink>
                <ExternalFooterLink href="https://dashboard.appmantle.com/signup">
                  Registreren
                </ExternalFooterLink>
              </Box>
              <Box width={[1, 1 / 2, 1 / 6]} mt={[3, 0, 0]}>
                <H5>Het bedrijf</H5>
                <FooterLink to="/over-appmantle/">Over Appmantle</FooterLink>
                <FooterLink to="/over-appmantle/">Contact</FooterLink>
                <FooterLink to="/vacatures/">Vacatures</FooterLink>
              </Box>
              <Box width={[1, 1 / 2, 1 / 6]}>
                <H5>Links</H5>
                <FooterLink to="/tarieven/">Veelgestelde vragen</FooterLink>
                <FooterLink to="/algemene-voorwaarden/">
                  Algemene voorwaarden
                </FooterLink>
                <FooterLink to="/privacyverklaring/">
                  Privacy verklaring
                </FooterLink>
              </Box>
            </Flex>
            <Copyright>
              <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap-reverse">
                <Box mt={[3, 0, 0]}>© Appmantle 2016 - {new Date().getFullYear()}</Box>
                <Box>
                  <FoundedLink href="https://foundedingroningen.com/">
                    <img
                      src={founded}
                      alt="Appmantle"
                      style={{ height: "2.5rem" }}
                    />
                  </FoundedLink>
                </Box>
              </Flex>
            </Copyright>
          </Container>
          <OverlayTop />
        </Background>
      </>
    );
  }
};

export default Footer;
