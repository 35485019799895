import React from "react";
import styled from "styled-components";

const H2 = styled.div`
  display: inline-block;
  font-size: ${({ theme }) => theme["size-2"]};
  font-weight: ${({ theme }) => theme["heading-weight"]};
  line-height: ${({ theme }) => theme["heading-lineheight"]};
`;

export default H2;
