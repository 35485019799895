import React from "react";
import styled, { css } from "styled-components";

import arrow from "../img/arrow-icon.svg";
import arrowBlue from "../img/arrow-icon-blue.svg";

const ArrowIcon = styled.span`
  display: inline-block;
  background: url(${({primary}) => primary ? arrow : arrowBlue});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 0.75em;
  height: 0.75em;
  margin-left: 0.5em;
  transition: 0.2s;
`;

const Style = styled.span`
  cursor: pointer;
  border: none;
  border-radius: 2em;
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  transition: 0.2s;
  ${({ large, theme }) =>
    large === true
      ? css`
          padding: 1rem 1.5rem;
          font-size: ${theme["size-4"]};
        `
      : css`
          padding: 0.6rem 1rem;
          font-size: ${theme["size-base"]};
        `}
  ${({ theme, primary }) =>
    primary === true
      ? css`
          background: ${theme.colors.primary};
          color: ${theme.colors.primaryInverse};
        `
      : css`
          background: ${theme.colors.primaryLight};
          color: ${theme.colors.primary};
        `}}
  &:hover {
    opacity: 0.7;
    > ${ArrowIcon} {
      transform: translateX(5px);
    }
  }
`;

export default function Button({ children, large = false, primary = false, withArrow, ...props }) {
  return (
    <Style large={large} primary={primary} {...props}>
      {children} {withArrow && <ArrowIcon primary={primary}/>}
    </Style>
  );
}
