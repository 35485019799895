import React from "react";
import styled, { css } from "styled-components";

const Paragraph = styled.p`
  line-height: 1.6;
  ${({ small, theme }) =>
    small === true &&
    css`
      font-size: ${theme["size-small"]};
    `}
`;

export default Paragraph;
