import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.svg";
import styled, { css } from "styled-components";
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Container from "../components/Container";
import { Box, Flex } from "@rebass/grid";

const ExternalLink = styled(OutboundLink)`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  margin: 0.5rem;
  &:hover {
    opacity: 0.6;
  }
`;

const SignUpLink = styled(OutboundLink)`
  display: block;
  text-decoration: none;
  opacity: 1;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border-radius: 2em;
  ${({ theme }) => css`
    color: ${theme.colors.text};
    background: ${theme.colors.primaryLight};
    color: ${theme.colors.primary};
  `}
  &:hover {
    opacity: 0.6;
  }
`;

const NavLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  margin: 0.5rem;
  &:hover {
    opacity: 0.6;
  }
  &.active {
    font-weight: 600;
  }
`;

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
    return (
      <Container as="nav">
        <Flex
          justifyContent="space-between"
          py={3}
          flexWrap="wrap"
          alignItems="center"
        >
          <Box>
            <Link to="/" title="Logo">
              <img src={logo} alt="Appmantle" style={{ width: "9.25rem" }} />
            </Link>
          </Box>
          <Box>
            <Flex flexWrap="wrap" style={{ margin: "0 -0.5em" }}>
              <NavLink to="/product/" activeClassName="active">
                Product
              </NavLink>
              <NavLink to="/tarieven/" activeClassName="active">
                Tarieven
              </NavLink>
              <NavLink to="/over-appmantle/" activeClassName="active">Over Appmantle</NavLink>
              <ExternalLink href="https://dashboard.appmantle.com/">Inloggen</ExternalLink>
              <SignUpLink href="https://dashboard.appmantle.com/signup">Begin nu gratis</SignUpLink>
            </Flex>
          </Box>
        </Flex>
      </Container>
    );
  }
};

export default Navbar;
